body {
  font-size: calc(8px + 2vmin);
  color: #2b2a2d;
}

.App {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.hero-bg {
  background-size: cover;
  background-position-y: 80%, 40%,50%;
  padding-bottom: 20px;
  margin-bottom: -65px;
}

.footer-bg {
  background-size: cover;
  background-position-y: 12%, 30%, 10%;
  padding-top: 30px;
  margin-top: -30px;
  width: 100%;
  position: fixed;
  bottom: 0;
}

.card-link {
  text-decoration: none;
  position: absolute;
}
.card-link::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}

.highlighted-card {
  color: orange;
  font-weight: 600;
  transition: transform 0.3s ease-in-out;
  transform: scale3d(1.02, 1.02, 1);
}

.vertical-divider {
  border-width: 0;
  border-right-width: 1px;
  border-style: solid;
  border-image: linear-gradient(185deg, rgba(185,185,185,0) 0%, rgba(185,185,185,0.5018382352941176) 25%, rgba(185,185,185,0.6530987394957983) 50%, rgba(185,185,185,0.5046393557422969) 75%, rgba(185,185,185,0) 100%) 1;
}

.horizontal-divider {
  border-width: 0;
  border-bottom-width: 1px;
  border-style: solid;
  border-image: linear-gradient(90deg, rgba(185,185,185,0) 0%, rgba(185,185,185,0.5018382352941176) 25%, rgba(185,185,185,0.6530987394957983) 50%, rgba(185,185,185,0.5046393557422969) 75%, rgba(185,185,185,0) 100%) 1;
}

.with-transition {
  transition: background-color 0.8s ease-in-out;
  border-radius: 3px;
}

.ripple{
	position: relative; /*Position relative is required*/
	display: block;
	outline: none;
	padding: 20px;
	box-sizing: border-box;
  overflow: hidden;
}

.ripple:hover:before{
	animation: ripple 0.6s ease-out;
}

.ripple:before{
	content:"";
	position:absolute;
  top:0;
  left:0;
	width:100%;
  height:100%;
  background-color:rgba(0, 71, 174, 0.438);
  border-radius:50%;
	transform: scale(0);
}

.ripple-black {
	position: relative; /*Position relative is required*/
	display: block;
	outline: none;
	box-sizing: border-box;
  overflow: hidden;
}

.ripple-black:hover:before{
	animation: ripple 0.6s ease-out;
}

.ripple-black:before{
	content:"";
	position:absolute;
  top:0;
  left:0;
	width:100%;
  height:100%;
  background-color:rgba(37, 37, 37, 0.568);
  border-radius:50%;
	transform: scale(0);
}

@keyframes ripple{
	from{transform: scale(0); opacity: 1;}
	to{transform: scale(1.5); opacity: 0;}
}